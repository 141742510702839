import { formatDate, NumberMxn } from "helpers";
import TextInfo from "../text-info";
import {
  FaRegCheckCircle,
  FaCloudDownloadAlt,
  FaEye,
  FaPeopleCarry,
  FaCopy,
  FaFilePdf,
  FaEdit,
} from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { FaHandsHelping } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { AiOutlineAudit } from "react-icons/ai";

import BtnNew from "components/btn-new";
import toast from "react-hot-toast";
import Lottie from "lottie-react";
import animationblue from "../../../../assets/animation-hand-blue.json";
import animationGreen from "../../../../assets/hand-green.json";
import Swal from "sweetalert2";
import { useState } from "react";
import api from "../../../../service";

const bgs = {
  proceso: "bg-yellow-100",
  autorizada: "bg-green-100",
  cancelada: "bg-red-100",
  solicitada: "bg-gray-100",
  "devolucion-email": "bg-yellow-100",
};

export default function ListSolicitudes({
  item,
  rol,
  status,
  handleView,
  setModalAcuse,
  setidGeneralSeleccionado,
  setModalAsignar,
  setShowSimulador,
  token,
  urlBase,
  onRefresh,
  onViewLogs,
  registerCustomEvent,
}) {
  const { general, oferta, asesor, mesa } = item;
  const [loader, setIsloader] = useState(false);
  const [typeAnimation, setTypeAnimation] = useState("blue");
  const [openMenu, setOpenMenu] = useState(false);

  const handleSendStp = (id) => {
    Swal.fire({
      title: "Se enviara la disperción por stp de 1 centavo.",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        const endpoint = `${urlBase}/solicitudes/${id}/reintento-stp`;
        fetch(endpoint, options).finally(() => {
          toast.success("La disperción se envio de forma correcta", {
            duration: 3000,
            position: "top-right",
          });
        });
      }
    });
  };

  const handleConfirmar = (id) => {
    Swal.fire({
      title: "Autorizar.",
      text: "Se autorizara la solicitud",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsloader(true);
        setTypeAnimation("green");
        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        const endpoint = `${urlBase}/solicitudes/${id}/authorization`;
        fetch(endpoint, options)
          .then((res) => {
            if (!res.ok) {
              return res.json().then((error) => {
                throw new Error("Ha ocurrido un error inesperado");
              });
            }
            return res.json();
          })
          .then((json) => {
            if (json.message) {
              toast.success(json.message, {
                duration: 6000,
                position: "top-center",
              });
            } else {
              toast.success("La solicitud ha sido autorizada", {
                duration: 6000,
                position: "top-center",
              });
            }
            return json;
          })
          .catch((err) => {
            toast.success("Ocurrio un error contacta a soporte", {
              duration: 6000,
              position: "top-center",
            });
          })
          .finally(() => {
            setTypeAnimation("blue");
            setIsloader(false);
            onRefresh();
          });
      }
    });
  };

  const handleDownload = ({ id, preview }) => {
    const endpoint =
      urlBase + `/solicitudes/download-zip/${id}?preview=${preview ?? false}`;
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    setIsloader(true);
    fetch(endpoint, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al descargar el archivo ZIP");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        if (preview) {
          a.download = `preview-credito-${id}.pdf`;
        } else {
          a.download = `paquete-credito-${id}.zip`;
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        registerCustomEvent(
          preview
            ? "Descargo la previsualización de contratos"
            : "Descargo el paquete de credito",
          {
            solicitudId: general.id,
          }
        );
        setIsloader(false);
      });
  };

  const renderReenviarFirma = () => {
    if (general.countSignature) return null;
    if (general.sendTakePhoto || general.documentId)
      return (
        <BtnNew
          handleClick={() => {
            handleSendContratos(general.id, true);
          }}
          visible={true}
          Icon={FaFilePdf}
        >
          Reenviar firma
        </BtnNew>
      );
  };

  const autorizar = ({ status, id, isShow, acuse }) => {
    if (!isShow) return null;
    if (status !== "proceso") return null;
    return (
      <BtnNew
        handleClick={() => {
          if ([4, 6].includes(rol) && !acuse) {
            return Swal.fire({
              title: "Permiso",
              text: "Se autorizara la solicitud si acuse",
              showCancelButton: true,
              confirmButtonText: "Aceptar",
              cancelButtonText: "Cerrar",
              icon: "warning",
            }).then(async (result) => {
              if (result.isConfirmed) {
                handleConfirmar(id);
              }
            });
          }

          if (!acuse) {
            return Swal.fire({
              title: "Aviso",
              text: "No se ha generado el acuse cep, intente más tarde",
            });
          } else {
            return handleConfirmar(id);
          }
        }}
        visible={[1, 4, 6].includes(rol)}
        Icon={FaHandsHelping}
        colorIcon={"text-white-300"}
        bgBtn={"bg-green-400"}
      >
        Autorizar solicitud
      </BtnNew>
    );
  };

  const CancelarSolicitud = ({ id, status }) => {
    if (status == "proceso" || status == "autorizada" || status == "Devuelta") {
      return (
        <BtnNew
          handleClick={() => {
            Swal.fire({
              title: "Cancelar.",
              text: "Se cancelara la solicitud",
              showCancelButton: true,
              confirmButtonText: "Aceptar",
              cancelButtonText: "Cerrar",
              icon: "warning",
              input: "text",
              inputPlaceholder: "Ingrese el comentario de cancelar",
              inputValidator: (value) => {
                if (!value) {
                  return "Este campo es requerido";
                }
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                setIsloader(true);

                const userInput = result.value;
                const endpoint = `/solicitudes/${id}/change-status?comment=${userInput}`;
                await api
                  .put(endpoint, { textStatus: "cancelada" })
                  .finally(() => {
                    onRefresh();
                    setIsloader(false);
                  });
              }
            });
          }}
          visible={[1, 4, 6].includes(rol)}
          Icon={MdCancel}
          colorIcon={"text-red-300"}
        >
          {status === "proceso" ? "Cancelar solicitud" : null}
          {status === "autorizada" ? "Rechazar solicitud" : null}
        </BtnNew>
      );
    }
  };

  const handleSendContratos = (id, reenvio) => {
    Swal.fire({
      title: reenvio
        ? "Se reenvira la firma al cliente"
        : "Se iniciara el proceso de firma.",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsloader(true);

        const options = {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        const endpoint = `${urlBase}/solicitudes/${id}/send-take-photo`;
        fetch(endpoint, options).finally(() => {
          toast.success("El proceso se inicio correctamente", {
            duration: 3000,
            position: "top-right",
          });
          setIsloader(false);
          onRefresh();
        });
      }
    });
  };

  const renderReenviarAcuse = () => {
    if (
      general.idStp &&
      ["proceso"].includes(general.estatus) &&
      !general.isAcuseCep
    ) {
      return (
        <BtnNew
          handleClick={() => {
            handleSendStp && handleSendStp(general.id);
            registerCustomEvent("Click en reenviar acuse cep", {
              solicitudId: general.id,
            });
          }}
          visible={[1, 4, 6, 9].includes(rol)}
          Icon={IoIosSend}
        >
          Reenviar acuse cep
        </BtnNew>
      );
    }
    if (general.idStp || general.codeRastreo) {
      return (
        <BtnNew
          handleClick={() => {
            if (general.codeRastreo) {
              registerCustomEvent("Click en acuse cep", {
                solicitudId: general.id,
              });
              setModalAcuse(true);
              setidGeneralSeleccionado(general.id);
            } else {
              toast.error("Esta solicitud no generó la clave de rastreo", {
                position: "top-center",
              });
            }
          }}
          visible={[1, 4, 6].includes(rol)}
          Icon={FaEye}
        >
          Ver acuse cep
        </BtnNew>
      );
    }
  };

  const renderButtons = () => {
    return (
      <>
        {status !== "reasignacion" && (
          <BtnNew
            handleClick={() => {
              registerCustomEvent("Click en validar datos", {
                solicitudId: general.id,
              });
              handleView(item);
            }}
            className="flex h-[30px] items-center justify-center rounded-[10px] bg-brand-400 p-2 text-white"
            visible={true}
            Icon={FaRegCheckCircle}
            colorIcon={"text-green-300"}
          >
            Validar Datos
          </BtnNew>
        )}
        <div class="relative">
          <button
            class="flex w-full items-center justify-between rounded-lg bg-blue-400 px-4 py-2 text-white hover:bg-blue-500"
            onClick={() => {
              setOpenMenu((old) => !old);
            }}
          >
            <div class="flex items-center space-x-2">
              <FaCloudDownloadAlt />
              <span>Paquete de crédito</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              className={`h-5 w-5 ${openMenu && "rotate-[270deg]"}`}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>

          <div
            class={`rounded-b-lg bg-blue-200 shadow-lg ${
              openMenu ? "duration-700 ease-in-out" : "hidden"
            }`}
          >
            {[1, 4, 6, 8].includes(rol) && (
              <a
                href="#"
                class="block px-4 py-2  text-white hover:bg-blue-300"
                onClick={() => handleDownload({ id: general.id })}
              >
                Descargar paq. cred.
              </a>
            )}
            {[1, 4, 6, 8].includes(rol) && (
              <a
                href="#"
                class="block px-4 py-2  text-white hover:bg-blue-300"
                onClick={() =>
                  handleDownload({ id: general.id, preview: true })
                }
              >
                Prev. contrato
              </a>
            )}
          </div>
        </div>

        {renderReenviarAcuse()}

        {!(general.estatus === "autorizada") && (
          <BtnNew
            handleClick={() => {
              registerCustomEvent("Click en reasignar asesor", {
                solicitudId: general.id,
              });
              setModalAsignar(true);
              setidGeneralSeleccionado(general.id);
            }}
            visible={[1, 4, 6].includes(rol)}
            Icon={FaPeopleCarry}
          >
            Asignar asesor
          </BtnNew>
        )}

        {autorizar({
          status: general.estatus,
          isShow: general.countSignature > 0,
          id: general.id,
          acuse: general.isAcuseCep,
        })}
        {CancelarSolicitud({
          status: general.estatus,
          id: general.id,
        })}

        {general.estatus === "proceso" &&
          general.documentId === null &&
          !general.sendTakePhoto && (
            <BtnNew
              handleClick={() => {
                handleSendContratos(general.id);
              }}
              visible={true}
              Icon={FaFilePdf}
            >
              Enviar contratos
            </BtnNew>
          )}

        {renderReenviarFirma()}

        <BtnNew
          handleClick={() => {
            onViewLogs(general.id);
          }}
          visible={[1, 4, 6, 9].includes(rol)}
          Icon={AiOutlineAudit}
        >
          Ver auditoria
        </BtnNew>
      </>
    );
  };

  const getTextStatus = ({
    sendTakePhoto,
    documentId,
    countSignature,
    $status,
    isAcuseCep,
    codeRastreo
  }) => {
    if ($status === "autorizada") return "autorizada";
    if (
      (sendTakePhoto || documentId || $status === "devolucion-email") &&
      countSignature === 0
    ) {
      return "En espera de firma";
    }

    if (codeRastreo && !isAcuseCep) {
      return "En espera de acuse";
    }
    return $status;
  };

  return (
    <div className="grid w-full grid-cols-[auto,280px] gap-x-10 gap-y-5  bg-gray-100 duration-300">
      <div className="flex flex-col gap-y-2  rounded-md bg-[#fff] px-5 py-5">
        <div className="flex flex-row gap-x-10">
          <TextInfo label="Id:" value={general.id} />
          <TextInfo
            label="ESTATUS:"
            value={getTextStatus({
              sendTakePhoto: general.sendTakePhoto,
              documentId: general.documentId,
              countSignature: general.countSignature,
              $status: general.estatus,
              isAcuseCep: general.isAcuseCep,
              codeRastreo: general.codeRastreo,
            })}
            classContainer={bgs[general.estatus]}
          />
          <TextInfo label="Fecha y Hr:" value={formatDate(general.createdAt)} />
          <TextInfo label="Zell:" value={general.zellId} />
          <TextInfo label="Devoluciones:" value={general.countDevueltos} />
          <span className="flex flex-col px-2">
            <span className="text-[12px] uppercase">Id Veridas:</span>
            <button
              onClick={() => {
                navigator.clipboard.writeText(general?.validationVeridas);
              }}
            >
              <FaCopy className="text-brand-300" />
            </button>
          </span>
          <TextInfo label="Reserva:" value={general.reserva} />
          <TextInfo
            label="CONTRATOS FIRMADOS:"
            value={general.countSignature > 0 ? "si" : "no"}
            classContainer={
              general.countSignature > 0 ? "bg-green-300" : "bg-red-300"
            }
          />
          {general.manualCapture && (
            <TextInfo
              label="Captura"
              value={"MANUAL [FIMPE-RENAPO]"}
              classContainer={"bg-red-400"}
            />
          )}
        </div>
        <div className="border-2 border-dashed"></div>
        <div className="flex flex-row gap-x-10">
          <TextInfo
            label="Nombre del cliente:"
            value={`${general.nombre} ${general.secondName} ${general.apellidoP} ${general.apellidoM}`}
          />
          <TextInfo
            label="Número cliente:"
            value={`${general.numero_empleado}`}
            isCopy={true}
          />
          <TextInfo label="Nombre del Analista:" value={mesa?.fullName} />
          <TextInfo label="Nombre del Asesor:" value={asesor?.fullName} />
          {item?.externo && (
            <TextInfo
              label="Asesor externo:"
              value={item?.externo?.fullName}
            />
          )}
        </div>
        <div className="border-2 border-dashed"></div>
        <div className="flex w-[100%] flex-row gap-4 rounded-[10px] bg-brand-100 p-2">
          <TextInfo label="Tipo:" value={oferta?.tipo} />
          <TextInfo
            label="Monto del crédito:"
            value={NumberMxn(oferta?.total ?? 0)}
          />
          <TextInfo label="No de quicenas:" value={oferta?.plazo} />
          <TextInfo
            label="Pago quincenal:"
            value={NumberMxn(oferta?.pago ?? 0)}
          />
          <TextInfo
            label="Solvencia:"
            value={NumberMxn(oferta?.capacidad ?? 0)}
          />
          <TextInfo
            label="Monto a dispersar:"
            value={NumberMxn(oferta?.dispocicion ?? 0)}
          />
          <TextInfo
            label="Pago total:"
            value={NumberMxn(oferta?.pagoTotal ?? 0)}
          />
          {general.documentId === null ? (
            general.estatus === "autorizada" ? null : (
              <button
                className=""
                onClick={() => {
                  registerCustomEvent("Click en recalcular oferta", {
                    solicitudId: general.id,
                  });

                  setShowSimulador(general);
                }}
              >
                <FaEdit />
              </button>
            )
          ) : null}
        </div>
        {general.comments && (
          <div>
            <TextInfo label="Cancelada por:" value={general.comments} />
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center gap-2 rounded-md bg-[#fff] px-5 py-5">
        {loader ? (
          <div>
            <Lottie
              animationData={
                typeAnimation === "green" ? animationGreen : animationblue
              }
              loop={true}
            />
            <span>CARGANDO ...</span>
          </div>
        ) : (
          renderButtons()
        )}
      </div>
    </div>
  );
}
