import { useEffect, useState } from "react";
import { X } from "lucide-react";
import FileUpload from "./file-upload";
import ConfigCard from "./config-card";
import api from "service";
import Grid from "components/grid";
import { useSelector } from "react-redux";

const Modal = ({ isOpen, onClose, title, children }) => {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-2xl font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="rounded-full p-1 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const Datas = () => {
  const [activeModal, setActiveModal] = useState();
  const [data, setData] = useState([]);
  const [ip, setIp] = useState("");
  const { rol } = useSelector((state) => state.auth);

  const handleViewList = (listType) => {
    setActiveModal(`view-${listType}`);

    const params = {
      periods: "",
      blacklist: "blacklist",
    };
    let endpoint = `system/black-list?type=${params[listType] ?? "producto"}`;
    if (listType === "periods") {
      endpoint = `system/periodos`;
    } else if (listType === "ips") {
      endpoint = `system/ip`;
    }
    api
      .get(endpoint)
      .then((res) => {
        setData(res);
      })
      .finally(() => {});
  };

  const handleUpload = (uploadType) => {
    setActiveModal(`upload-${uploadType}`);
  };

  const getType = () => {
    return activeModal?.split("-")[1] === "periods"
      ? "periodos"
      : activeModal?.split("-")[1] === "blacklist"
      ? "blacklist"
      : "producto";
  };

  const getColumns = () => {
    const views = activeModal?.split("-")[1];

    if (views === "periods") {
      return [
        { name: "Inicio", field: "start" },
        { name: "Fin", field: "end" },
        { name: "Fecha pago", field: "payment" },
      ];
    }
    if (views === "blacklist") {
      return [
        { name: "Nombre", field: "name" },
        { name: "CURP", field: "curp" },
      ];
    }

    if (views === "ips") {
      return [{ name: "Ip", field: "valor" }];
    }
    return [
      { name: "Nombre", field: "name" },
      { name: "CURP", field: "curp" },
      { name: "PRODUCTO", field: "product" },
    ];
  };

  const getTitle = (key) => {
    const list = {
      periods: "Subir Periodos",
      blacklist: "Subir Lista Negra",
      ips: "Agregar ips",
    };

    return list[key] || "Lista Negra Productos";
  };

  const getTitleList = (key) => {
    const list = {
      periods: "Lista Periodos",
      blacklist: "Lista Negra",
      ips: "Lista de ips",
    };

    return list[key] || "Lista Negra Productos";
  };

  return (
    <div className="min-h-screen px-2 py-10">
      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {[1, 9].includes(rol) && (
            <ConfigCard
              title="Periodos"
              onViewList={() => handleViewList("periods")}
              onUpload={() => handleUpload("periods")}
            />
          )}

          {[1, 9, 11].includes(rol) && (
            <ConfigCard
              title="Lista Negra"
              onViewList={() => handleViewList("blacklist")}
              onUpload={() => handleUpload("blacklist")}
            />
          )}

          {[1, 9, 11].includes(rol) && (
            <ConfigCard
              title="Lista Negra Productos"
              onViewList={() => handleViewList("products")}
              onUpload={() => handleUpload("products")}
            />
          )}

          {[1, 9].includes(rol) && (
            <ConfigCard
              title="Ips permitidas"
              onViewList={() => handleViewList("ips")}
              onUpload={() => handleUpload("ips")}
              btnNormal={true}
            />
          )}
        </div>

        <Modal
          isOpen={activeModal?.startsWith("view-")}
          onClose={() => setActiveModal(null)}
          title={getTitleList(activeModal?.split("-")[1])}
        >
          <div className="space-y-4">
            <p className="text-gray-600">
              {activeModal?.split("-")[1] === "periods"
                ? "Lista de periodos disponibles"
                : activeModal?.split("-")[1] === "blacklist"
                ? "Lista negra de usuarios"
                : "Lista negra de productos"}
            </p>
            {data && data.length > 0 ? (
              <Grid colums={getColumns()} data={data} />
            ) : (
              <div className="rounded-lg bg-gray-50 p-4 text-center text-gray-500">
                No hay elementos para mostrar
              </div>
            )}
          </div>
        </Modal>

        <Modal
          isOpen={activeModal?.startsWith("upload-")}
          onClose={() => setActiveModal(null)}
          title={`${getTitle(activeModal?.split("-")[1])}`}
        >
          {activeModal?.indexOf("ips") > -1 ? (
            <div className="flex flex-col items-center gap-4">
              {ip != "" ? (
                <>
                  <p className="mb-2 text-lg">La ip publica es {ip}</p>
                  <button
                    onClick={async () => {
                      api
                        .post("system/ip", {
                          ip,
                        })
                        .then((res) => {
                          setIp("");
                          setActiveModal("");
                        });
                    }}
                    className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
                  >
                    Guardar para dar acceso
                  </button>
                </>
              ) : (
                <>
                  <p className="mb-2 text-lg">
                    Conectate a la red en la cual buscaras la ip publica
                  </p>
                  <button
                    onClick={async () => {
                      const response = await fetch(
                        "https://api.ipify.org?format=json",
                        {
                          method: "GET",
                        }
                      );

                      const responseData = await response.json();
                      setIp(responseData.ip);
                    }}
                    className="hover:bg-primary/90 rounded-lg bg-custom-green px-4 py-2 text-white transition-colors"
                  >
                    Obtener ip publica
                  </button>
                </>
              )}
            </div>
          ) : (
            <FileUpload
              handleClose={() => {
                setActiveModal();
              }}
              type={getType()}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Datas;
